// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function stopwatch(btn) {
  var input = btn.line.els[0];
  var ident = btn.attr('id');
  var timerEl = btn.next();

  // start stopwatch
  if (!timerEl.is('.timer')) {
    btn.seconds = 0;
    var timerObj = JSON.parse(localStorage.getItem('timer'));
    if (!timerObj) timerObj = {};
    if (timerObj[ident])
      btn.seconds = Math.round((Date.now() - timerObj[ident]) / 1000);
    else {
      var timers = Object.keys(timerObj);
      if (timers.length >= 25)
        for (var i = 0; i < 20; i++) delete timerObj[timers[i]];
      timerObj[ident] = Date.now();
      localStorage.setItem('timer', JSON.stringify(timerObj));
    }

    timerEl = $(
      '<span class="input-group-text timer">' +
        secondsToStr(btn.seconds) +
        '</span>'
    ).insertAfter(btn);
    btn.timerInterval = setInterval(function () {
      btn.seconds++;
      timerEl.text(secondsToStr(btn.seconds));
    }, 1000);
  }

  // end stopwatch
  else {
    clearInterval(btn.watch);
    var addHours = Math.ceil((btn.seconds / 3600) * 100) / 100;
    var currentHours = input.val();
    input.val(currentHours + addHours);
    input.save();
    timerEl.remove();
    timerObj = JSON.parse(localStorage.getItem('timer'));
    if (!timerObj) timerObj = {};
    delete timerObj[ident];
    localStorage.setItem('timer', JSON.stringify(timerObj));
  }
}
